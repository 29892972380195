<template>
  <div>
    <!-- 電腦版 -->
    <div>
      <!-- 上半部 -->
      <div id="MerchandiseTopArea" class="ms-1 row mb-1">
        <!-- 按鈕 -->
        <div class="col-md-6 col-12 mb-1 d-flex flex-wrap align-items-center">
          <!-- 常用搜尋 -->
          <div class="dropdown d-inline-block me-1 mb-1">
            <button
              class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
              id="FrequentlySearched"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              常用搜尋
            </button>
            <ul class="dropdown-menu" aria-labelledby="FrequentlySearched">
              <li>
                <button class="dropdown-item" @click="frequentlySearchStatus = '訂閱中(全部)'; tableData = sellerUsageSituationList ; searchGlobal.global.value = '訂閱中'">
                  訂閱中(全部)
                </button>
              </li>
              <li>
                <button class="dropdown-item" @click="frequentlySearchStatus = '訂閱中(年費)' ; tableData = frequentlySearchData.subscribingYear ; searchGlobal.global.value = null" > 
                  訂閱中(年費)
                </button>
              </li>
              <li>
                <button class="dropdown-item" @click="frequentlySearchStatus = '訂閱中(季費)' ; tableData = frequentlySearchData.subscribingSeason ; searchGlobal.global.value = null">
                  訂閱中(季費)
                </button>
              </li>
              <li>
                <button class="dropdown-item" @click="frequentlySearchStatus = '試用中(全部)' ; tableData = sellerUsageSituationList ; searchGlobal.global.value = '試用中'">
                  試用中(全部)
                </button>
              </li>
              <li>
                <button class="dropdown-item" @click="frequentlySearchStatus = '試用中(本月)' ; tableData = frequentlySearchData.currentTry ; searchGlobal.global.value = null">
                  試用中(本月)
                </button>
              </li>
              <li>
                <button class="dropdown-item" @click="frequentlySearchStatus = '試用中(次月)' ; tableData = frequentlySearchData.nextTry ; searchGlobal.global.value = null">
                  試用中(次月)
                </button>
              </li>
              <li>
                <button class="dropdown-item" @click="frequentlySearchStatus = '未續訂' ; tableData = sellerUsageSituationList ; searchGlobal.global.value = '未續訂'">
                  未續訂
                </button>
              </li>
              <li>
                <button class="dropdown-item" @click="frequentlySearchStatus = '未開通' ; tableData = sellerUsageSituationList ; searchGlobal.global.value = '未開通'">
                  未開通
                </button>
              </li>
              <li>
                <button class="dropdown-item" @click="frequentlySearchStatus = '全部' ; tableData = sellerUsageSituationList ; searchGlobal.global.value = null">
                  全部
                </button>
              </li>
            </ul>
          </div>
        </div>
        <!-- 資訊 -->
        <div class="col-md-6 col-12 fw-bolder">
          <p class="mb-1">全部: {{ sellerCategoryCount.all }} 人 、 未續訂: {{ sellerCategoryCount.notReSubscription }} 人 、 未開通: {{ sellerCategoryCount.unVerify }} 人</p>
          <p>訂閱中: {{ sellerCategoryCount.subscribing }} 人 (季費: {{ sellerCategoryCount.subscribingSeason }} 人, 年費: {{ sellerCategoryCount.subscribingYear }} 人) | 試用中: {{ sellerCategoryCount.try }} 人 (本月: {{ sellerCategoryCount.currentTry }} 人, 次月: {{ sellerCategoryCount.nextTry }} 人)</p>
        </div>
      </div>
      <!-- dataTable  -->
      <div class="tw-container">
        <div class="management-datatable">
          <DataTable
            class="p-datatable-sm"
            :scrollHeight="`${scrollHeight}px`"
            :value="tableData"
            dataKey=""
            :loading="dataTabelLoading"
            :rowHover="true"
            :paginator="true"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rows="100"
            :rowsPerPageOptions="[20, 50, 100]"
            :scrollable="true"
            currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
            v-model:filters="searchGlobal"
            filterDisplay="menue"
            stateStorage="local"
            stateKey="dt-state-sellerUsageSituationList-local"
            sortField="storeCreateTime"
            :sortOrder="-1"
          >
            <template #header>
              <div class="d-flex align-items-center">
                <div class="w-100">
                  <div class="d-flex justify-content-between my-1">
                    <span>表格搜尋 <span class="badge bg-primary me-2">{{ frequentlySearchStatus }}</span></span>
                    <!-- <span class="text-end">資料更新時間 : 2023/09/13 上午 10:06</span> -->
                  </div>
                  <input
                    class="form-control me-2"
                    v-model="searchGlobal['global'].value"
                    placeholder="表格搜尋: 任何欄位都能搜"
                    style="max-width: 99%"
                  />
                </div>
              </div>
            </template>
            <!-- ID -->
            <Column
              field="id"
              header="ID"
              sortable
              style="min-width: 55px; max-width: 70px"
            ></Column>
            <!-- 姓名 -->
            <Column
              field="name"
              header="姓名"
              sortable
              style="min-width: 150px"
            ></Column>
            <!-- 訂閱型態 -->
            <Column
              header="訂閱型態"
              style="min-width: 110px"
            >
              <template #body="{ data }">
                <div v-if="data.lastSubscriptionType">
                  <p v-if="data.subscriptionTypeCount[0] > 0" :class="[data.subscriptionTypeCount[1] > 0 || data.subscriptionTypeCount[2] > 0 ? 'mb-3' : '', data.lastSubscriptionType === 'Year' ? 'text-success fw-bolder' : '']">年費({{data.subscriptionTypeCount[0]}})</p>
                  <p v-if="data.subscriptionTypeCount[1] > 0" :class="[data.subscriptionTypeCount[2] > 0 ? 'mb-3' : '', data.lastSubscriptionType === 'Season' ? 'text-success fw-bolder' : '']">季費({{data.subscriptionTypeCount[1]}})</p>
                  <p v-if="data.subscriptionTypeCount[2] > 0" :class="data.lastSubscriptionType === 'UnKnow' ? 'text-success fw-bolder' : ''">UnKnow({{data.subscriptionTypeCount[2]}})</p>
                </div>
                <p v-else>-</p>
              </template>
            </Column>
            <!-- 訂閱狀態 -->
            <Column
              field="currentStatus"
              header="訂閱狀態"
              sortable
              style="min-width: 110px"
            ></Column>
            <!-- 訂閱到期 -->
            <Column
              field="expirationTime"
              header="訂閱到期"
              sortable
              style="min-width: 110px"
            >
              <template #body="{ data }">
                <p :class="$methods.moment().diff( data.expirationTime, 'days') >= -10 && $methods.moment().diff( data.expirationTime, 'days') < 1 ? 'text-danger' : ''"> {{data.expirationTime}} </p>
              </template>
            </Column>
            <!-- 開立賣場名稱 -->
            <Column
              field="storeName"
              header="賣場"
              sortable
              style="min-width: 200px"
            >
              <template #body="{ data }">
                <div v-for="(item,index) in data.storeName" :key="index+item">
                  <p :class="index+1 !== data.storeName.length ? 'mb-3' : ''"> {{item}} </p>
                </div>
              </template>
            </Column>
            <!-- 賣場開立時間 -->
            <Column
              field="storeCreateTime"
              header="賣場開立"
              sortable
              style="min-width: 110px"
            >
              <template #body="{ data }">
                <div v-for="(item,index) in data.storeCreateTime" :key="index+item">
                  <p :class="index+1 !== data.storeCreateTime.length ? 'mb-3' : ''"> {{item}} </p>
                </div>
              </template>
            </Column>
            <!-- 顧客數 -->
            <Column
              field="participanCountfilter"
              header="顧客數"
              sortable
              style="min-width: 55px; max-width: 100px"
            >
              <template #body="{ data }">
                <div v-for="(item,index) in data.participanCount" :key="index+item">
                  <p :class="index+1 !== data.participanCount.length ? 'mb-3' : ''"> {{item}} </p>
                </div>
              </template>
            </Column>
            <!-- 已建立商品數 -->
            <Column
              field="merchandiseCountfilter"
              header="建立商品"
              sortable
              style="min-width: 100px; max-width: 110px"
            >
              <template #body="{ data }">
                <div v-for="(item,index) in data.merchandiseCount" :key="index+item">
                  <p :class="index+1 !== data.merchandiseCount.length ? 'mb-3' : ''"> {{item}} </p>
                </div>
              </template>
            </Column>
            <!-- 有發布FB商品數 -->
            <Column
              field="merchandiseFBCountfilter"
              header="發布FB"
              sortable
              style="min-width: 110px; max-width: 100px"
            >
              <template #body="{ data }">
                <div v-for="(item,index) in data.merchandiseFBCount" :key="index+item">
                  <p :class="index+1 !== data.merchandiseFBCount.length ? 'mb-3' : ''"> {{item}} </p>
                </div>
              </template>
            </Column>
            <!-- 有發布LINE商品數 -->
            <Column
              field="merchandiseLineCountfilter"
              header="發布LINE"
              sortable
              style="min-width: 89px; max-width: 110px"
            >
              <template #body="{ data }">
                <div v-for="(item,index) in data.merchandiseLineCount" :key="index+item">
                  <p :class="index+1 !== data.merchandiseLineCount.length ? 'mb-3' : ''"> {{item}} </p>
                </div>
              </template>
            </Column>
            <!-- 商品最後發布時間 -->
            <Column
              field="lastMerchandiseCreateTime"
              header="商品最後發布"
              sortable
              style="min-width: 110px"
            >
              <template #body="{ data }">
                <div v-for="(item,index) in data.lastMerchandiseCreateTime" :key="index+item">
                  <p :class="index+1 !== data.lastMerchandiseCreateTime.length ? 'mb-3' : ''"> {{item ? item : '-'}} </p>
                </div>
              </template>
            </Column>
            <!-- 訂單數 -->
            <Column
              field="merchOrderCountfilter"
              header="訂單數"
              sortable
              style="min-width: 55px; max-width: 100px"
            >
              <template #body="{ data }">
                <div v-for="(item,index) in data.merchOrderCount" :key="index+item">
                  <p :class="index+1 !== data.merchOrderCount.length ? 'mb-3' : ''"> {{item}} </p>
                </div>
              </template>
            </Column>
            <!-- 訂單最後建立時間 -->
            <Column
              field="lastmerchOrderCreateTime"
              header="訂單最後建立"
              sortable
              style="min-width: 110px"
            >
              <template #body="{ data }">
                <div v-for="(item,index) in data.lastmerchOrderCreateTime" :key="index+item">
                  <p :class="index+1 !== data.lastmerchOrderCreateTime.length ? 'mb-3' : ''"> {{item ? item : '-'}} </p>
                </div>
              </template>
            </Column>
            <!-- 帳單數 -->
            <Column
              field="billCountfilter"
              header="帳單數"
              sortable
              style="min-width: 55px; max-width: 100px"
            >
              <template #body="{ data }">
                <div v-for="(item,index) in data.billCount" :key="index+item">
                  <p :class="index+1 !== data.billCount.length ? 'mb-3' : ''"> {{item}} </p>
                </div>
              </template>
            </Column>
            <!-- 最後結單時間 -->
            <Column
              field="billCountCreateTime"
              header="最後結單"
              sortable
              style="min-width: 110px"
            >
              <template #body="{ data }">
                <div v-for="(item,index) in data.billCountCreateTime" :key="index+item">
                  <p :class="index+1 !== data.billCountCreateTime.length ? 'mb-3' : ''"> {{item ? item : '-'}} </p>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
    <!-- todo 手機板 -->
    <div></div>
  </div>
</template>

<script>
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import { FilterMatchMode } from 'primevue/api';
// api
import { parseToken, sortoutParseTokenResult } from '../../../methods/API/parseToken'

export default {
  components: {DataTable, Column, ColumnGroup, Row},
  data() {
    return {
      // data
      b_serverToken: '',
      // dataTable
      scrollWidth: 0,
      scrollHeight: 0,
      dataTabelLoading: false,
      sellerUsageSituationList: [],
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      fields: [],
      // 人數計算
      sellerCategoryCount: {
        all: 0,
        try: 0,
        currentTry: 0,
        nextTry: 0,
        subscribing: 0,
        subscribingYear: 0,
        subscribingSeason: 0,
        notReSubscription: 0,
        unVerify: 0
      },
      // 常用搜尋
      frequentlySearchData: {
        subscribingYear: [], // 訂閱中年費
        subscribingSeason: [], // 訂閱中季費
        currentTry: [], // 本月試用者 (前月21日 ~ 本月20日)
        nextTry: [], // 次月試用者 (本月21 ~ 次月20日)
      },
      frequentlySearchStatus: '全部',
      tableData:[],
    }
  },
  created() {
    this.initialization()
    this.getUserInfo()
    this.getSellerUsageSituation()
  },
  unmounted() {
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-sellerUsageSituationList-local')
  },
  methods: {
    // 初始化
    initialization() {
      this.b_serverToken = this.$methods.getCookie('b_serverToken') // 待確認
      // 紀錄 dataTabel 高度
      this.scrollHeight = window.innerHeight - 210
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-sellerUsageSituationList-local')
      // 將 dataTabel 顯示欄位預設值帶入
      const fields = ['','','','','','','']
    },
    // 取得使用者資訊
    getUserInfo() {
      this.$methods.switchLoading('show')
      const result = parseToken(this.b_serverToken)
      result.then(res => {
        const result = sortoutParseTokenResult(res)
        this.userInfo = result
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert('other', err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取得註冊情況
    getSellerUsageSituation() {
      this.dataTabelLoading = true
      this.$methods.switchLoading('show')
      const vm = this
      const getSellerUsageSituationApi = `${process.env.VUE_APP_API}/report/omc_getSellerUsageSituation`
      const header = {
        authorization: this.b_serverToken
      }
      const searchData = {
        currentStatus: ''
      }

      $.ajax({
        type: 'POST',
        async: true,
        url: getSellerUsageSituationApi,
        data: JSON.stringify(searchData),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const sellerUsageSituations = res.data
            console.log(sellerUsageSituations)
            vm.registrationSituationMap(sellerUsageSituations)
          } else {
            vm.SweetAlert(res.code, res.message)
            vm.$methods.switchLoading('hide')
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理註冊情況資料
    registrationSituationMap(registrationSituations) {
      const insider = [14273, 14687, 14266, 14267, 14787, 14484] // 內部人員 physicaluserId
      this.sellerUsageSituationList = []
      let idkey = 0
      registrationSituations.forEach((item) => {
        if (insider.includes(item.id)) return; // 過濾內部人員
        // 原資料
        const object = {
          id: item.id,
          idkey: idkey,
          name: item.name,
          subscriptionMoneyRecord: item.subscriptionMoneyRecord ?　item.subscriptionMoneyRecord.split(',') : '-',
          subscriptionTypeCount: [0, 0, 0],
          lastSubscriptionType: null,
          storeName: [item.storeName],
          storeCreateTime: [item.storeCreateTime ? this.$methods.moment(item.storeCreateTime).format("YYYY-MM-DD") : null],
          currentStatus: item.currentStatus,
          expirationTime: item.expirationTime ? this.$methods.moment(item.expirationTime).format("YYYY-MM-DD") : null,
          participanCount: [item.participanCount], 
          merchandiseCount: [item.merchandiseCount], 
          merchandiseFBCount: [item.merchandiseFBCount], 
          merchandiseLineCount: [item.merchandiseLineCount ? item.merchandiseLineCount : 0], 
          lastMerchandiseCreateTime: [item.lastMerchandiseCreateTime ? this.$methods.moment(item.lastMerchandiseCreateTime).format("YYYY-MM-DD") : null],
          merchOrderCount: [item.merchOrderCount],
          lastmerchOrderCreateTime: [item.lastmerchOrderCreateTime ? this.$methods.moment(item.lastmerchOrderCreateTime).format("YYYY-MM-DD") : null],
          billCount: [item.billCount],  
          billCountCreateTime: [item.billCountCreateTime ? this.$methods.moment(item.billCountCreateTime).format("YYYY-MM-DD") : null] ,
        }
        // 計算 訂閱數(年費、季費、UnKnow)
        if(object.subscriptionMoneyRecord != '-') {
          object.subscriptionMoneyRecord.forEach((money,index) => {
            if(money >= 6773) {
              // 年費
              object.subscriptionTypeCount[0]++
              if (index === 0) object.lastSubscriptionType = 'Year'
            } else if (money < 2205) {
              // UnKnow
              object.subscriptionTypeCount[2]++
              if (index === 0) object.lastSubscriptionType = 'UnKnow'
            } else {
              // 季費
              object.subscriptionTypeCount[1]++
              if (index === 0) object.lastSubscriptionType = 'Season'
            }
          })
        }
        // 處理 store 分流
        if (this.sellerUsageSituationList.length === 0) { 
          this.sellerUsageSituationList.push(JSON.parse(JSON.stringify(object)))
          idkey++
        } else {
          const findEqual = this.sellerUsageSituationList.filter(data => data.id === item.id)
          if (this.sellerUsageSituationList.filter(data => data.id === item.id).length === 0) { 
            this.sellerUsageSituationList.push(JSON.parse(JSON.stringify(object)))
            idkey++
          }
          else {
            this.sellerUsageSituationList[findEqual[0].idkey].storeName.push(object.storeName[0])
            this.sellerUsageSituationList[findEqual[0].idkey].storeCreateTime.push(object.storeCreateTime[0])
            this.sellerUsageSituationList[findEqual[0].idkey].participanCount.push(object.participanCount[0])
            this.sellerUsageSituationList[findEqual[0].idkey].merchandiseCount.push(object.merchandiseCount[0])
            this.sellerUsageSituationList[findEqual[0].idkey].merchandiseFBCount.push(object.merchandiseFBCount[0])
            this.sellerUsageSituationList[findEqual[0].idkey].merchandiseLineCount.push(object.merchandiseLineCount[0])
            this.sellerUsageSituationList[findEqual[0].idkey].lastMerchandiseCreateTime.push(object.lastMerchandiseCreateTime[0])
            this.sellerUsageSituationList[findEqual[0].idkey].merchOrderCount.push(object.merchOrderCount[0])
            this.sellerUsageSituationList[findEqual[0].idkey].lastmerchOrderCreateTime.push(object.lastmerchOrderCreateTime[0])
            this.sellerUsageSituationList[findEqual[0].idkey].billCount.push(object.billCount[0])
            this.sellerUsageSituationList[findEqual[0].idkey].billCountCreateTime.push(object.billCountCreateTime[0])
          }
        }
      })
      // 將陣列數值加總，供排序使用
      this.sellerUsageSituationList.forEach(item => {
        item.participanCountfilter = item.participanCount.reduce((a,b) => a + b )
        item.merchandiseCountfilter = item.merchandiseCount.reduce((a,b) => a + b )
        item.merchandiseFBCountfilter = item.merchandiseFBCount.reduce((a,b) => a + b )
        item.merchandiseLineCountfilter = item.merchandiseLineCount.reduce((a,b) => a + b )
        item.merchOrderCountfilter = item.merchOrderCount.reduce((a,b) => a + b )
        item.billCountfilter = item.billCount.reduce((a,b) => a + b )
      })
      console.log(this.sellerUsageSituationList)
      this.tableData = this.sellerUsageSituationList
      this.dataTabelLoading = false
      this.$methods.switchLoading('hide')
      this.frequentlySearched(this.sellerUsageSituationList)
    },
    // 常用搜尋資訊分類 (訂閱中年費、訂閱中季費、本月試用者 (前月21日 ~ 前前月20日)、 次月試用者 (前月21 ~ 本月20日)、未續訂)
    frequentlySearched(allData) {
      const currentMonth = this.$methods.moment(new Date()).add(-1, 'months').format("YYYY-MM")
      const lastMonth = this.$methods.moment(`${currentMonth}-20`).add(-1, 'months').format("YYYY-MM")
      const nextMount = this.$methods.moment(`${currentMonth}-20`).add(1, 'months').format("YYYY-MM")
      allData.forEach(item => {
        if (item.currentStatus === "訂閱中") {
          if(item.lastSubscriptionType === 'Year')  this.frequentlySearchData.subscribingYear.push(item)
          if(item.lastSubscriptionType === 'Season')  this.frequentlySearchData.subscribingSeason.push(item)
        }
        if (item.currentStatus !== '未開通') {
          // 取最初建立賣場的時間
          const storeCreateTimes = []
          item.storeCreateTime.forEach(storeCreateTime => {
            storeCreateTimes.push(new Date(storeCreateTime))
          })
          const minStoreCreateTime = this.$methods.moment(new Date(Math.min.apply(null,storeCreateTimes))).format("YYYY-MM-DD")
          // 本月試用者 (前月20日 ~ 本月21日)
          // console.log(`${lastMonth}-20 ~ ${currentMonth}-21`)
          if(this.$methods.moment(minStoreCreateTime).isBetween(`${lastMonth}-20`, `${currentMonth}-21`)) {
            this.frequentlySearchData.currentTry.push(item)
          }
          // 次月試用者 (本月20 ~ 次月21日))
          // console.log(`${currentMonth}-21 ~ ${nextMount}-20`)
          if(this.$methods.moment(minStoreCreateTime).isBetween(`${currentMonth}-20`, `${nextMount}-21`)) {
            this.frequentlySearchData.nextTry.push(item)
          }
        }
        
        // 計算人數
        if(item.currentStatus === '試用中') this.sellerCategoryCount.try++
        if(item.currentStatus === '訂閱中') this.sellerCategoryCount.subscribing++
        if(item.currentStatus === '未開通') this.sellerCategoryCount.unVerify++
        if(item.currentStatus === "未續訂") this.sellerCategoryCount.notReSubscription++
      })
      this.sellerCategoryCount.all = allData.length
      this.sellerCategoryCount.currentTry = this.frequentlySearchData.currentTry.length
      this.sellerCategoryCount.nextTry =  this.frequentlySearchData.nextTry.length
      this.sellerCategoryCount.subscribingYear =  this.frequentlySearchData.subscribingYear.length
      this.sellerCategoryCount.subscribingSeason =  this.frequentlySearchData.subscribingSeason.length
    },
  }
}
</script>